class Tabs {
    constructor() {
        this._tabsGsap();
      
    }

    _tabsGsap() {

        // tabs
        var tabLinks = document.querySelectorAll('.tablinks');
        //tabLinks[0].classList.add('active');

        var tabContent = document.querySelectorAll('.tabcontent');
        //tabContent[0].classList.add('active');

        // Setup
        tabLinks.forEach((link) => {
            link.addEventListener('click', () => openTabs(link));

            link.tl = gsap.timeline();
            link.tl.set('.tabcontent.active', {
                autoAlpha: 1,
                x: 0
            });
            link.tl.fromTo(
                '.tabcontent', {
                    autoAlpha: 0,
                    x: -60
                }, {
                    duration: 0.5,
                    autoAlpha: 1,
                    x: 0,
                    ease: 'power1.out'
                }
            );
        });

        // The link listener
        function openTabs(link) {
            if (!link.classList.contains('active')) {
                link.tl.play(0);

                document.querySelector('.tablinks.active').classList.remove('active');
                document.querySelector('.tabcontent.active').classList.remove('active');

                document.querySelector('#' + link.dataset.name).classList.add('active');
                link.classList.add('active');
            }
        }





    }

  
}




